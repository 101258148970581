var $ = require("jquery");

var SlideInImages = function() {
  var $window = $(window);
  var $els = $(".sliding");
  var windowHeight;
  var timer = null;

  window.addEventListener("scroll", handleScroll);
  window.addEventListener("resize", handleResize);

  setTimeout(function() {
    setup();
    process();
  }, 1000);

  $(window).on("SlideInImages", function() {
    setup();
    process();
  });

  function setup() {
    $els = $(".sliding");
    windowHeight = $window.height();
  }

  function process() {
    var scroll = $window.scrollTop();

    $els.each(function() {
      var $el = $(this);
      var offset = $el.offset();
      var height = $el.height();
      var elTop = offset.top - scroll;
      var elBottom = offset.top + height - scroll;

      if (elTop > windowHeight) {
        $el.css({
          transform: "translate3d(0, 80px, 0)",
          opacity: 0
        });
      } else if (elBottom < 50) {
        // $el.css({
        //   transform: "translate3d(0, -80px, 0)",
        //   opacity: 0
        // });
      } else {
        $el.css({
          transform: "translate3d(0, 0, 0)",
          opacity: 1
        });
      }
    });
  }

  function handleScroll() {
    if (timer == null) {
      timer = setTimeout(function() {
        process();
        clearTimeout(timer);
        timer = null;
      }, 100);
    }
  }

  function handleResize() {
    if (timer == null) {
      timer = setTimeout(function() {
        setup();
        process();
        clearTimeout(timer);
        timer = null;
      }, 500);
    }
  }
};

module.exports = SlideInImages;
