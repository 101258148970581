var $ = require("jquery");

var Header = function() {
  var currScroll = 0;
  var prevScroll = 0;

  var $body = $("body");

  $(window).on("scroll", function() {
    if (!$(".home-page").length) {
      currScroll = $(window).scrollTop();
      if (currScroll > 0) {
        $body.addClass("solid-header");
      } else {
        $body.removeClass("solid-header");
      }
      if (currScroll > 500) {
        // +ve down, -ve up
        if (currScroll - prevScroll < 0) {
          $body.removeClass("collapsed-header");
        } else {
          if (!$("body").hasClass("menu-open")) {
            $body.addClass("collapsed-header");
          }
        }
      } else {
        $body.removeClass("collapsed-header");
      }
      prevScroll = currScroll;
    }
  });
};

module.exports = Header;
