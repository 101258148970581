var $ = require("jquery");
var Platform = require("./vendor/Platform.js");

var VideoFilePlayer = function() {
  var $video = null;

  var $el = $(".video-file-player");
  if ($el.length) {
    if (!Platform.isTablet() && !Platform.isMobile()) {
      // eslint-disable-next-line
      Pace.on("done", function() {
        setTimeout(loadVideo, 600);
      });
    }
  }

  function loadVideo() {
    $video = $(
      "<video preload='auto' autoplay='true' loop='true'>" +
        "<source src='" +
        $el.data("video-src") +
        "'  type='video/mp4'>" +
        "</video>"
    );
    $video[0].volume = 0;
    $el.append($video);
    $video.one("play", function() {
      $(".hero-background-image").css("opacity", 0);
    });
  }
};

module.exports = VideoFilePlayer;
