var $ = require("jquery");

var QuickViewOverlay = function() {
  var $quickViewOverlay = $(".quick-view-overlay");
  var $overlayInner = $quickViewOverlay.find(".inner");
  var $closeBtn = $quickViewOverlay.find(".close-btn");

  $("body").on("click", ".quick-view", function(e) {
    e.preventDefault();

    var $this = $(this);
    var videoId = $this.data("video-id");
    var videoType = $this.data("video-type");
    var $iframe;

    if (videoType == "vimeo") {
      $iframe = $(
        "<iframe src='//player.vimeo.com/video/" +
          videoId +
          "?&autoplay=true' frameborder='0' allowfullscreen></iframe>"
      );
    } else {
      $iframe = $(
        "<iframe src='//www.youtube.com/embed/" +
          videoId +
          "?autoplay=true' frameborder='0' webkitallowfullscreen mozallowfullscreen allowfullscreen></iframe>"
      );
    }

    $("body").addClass("no-overflow");
    $quickViewOverlay.show();

    setTimeout(function() {
      $quickViewOverlay.css("opacity", 1);
      setTimeout(function() {
        $overlayInner.append($iframe);
      }, 500);
    }, 10);
  });

  $closeBtn.on("click", function() {
    $("body").removeClass("no-overflow");
    $quickViewOverlay.css("opacity", 0);

    setTimeout(function() {
      $quickViewOverlay.find("iframe").remove();
      $quickViewOverlay.hide();
    }, 500);
  });
};

module.exports = QuickViewOverlay;
