var SideMenu = require("./SideMenu");
var SearchOverlay = require("./SearchOverlay");
var Contact = require("./Contact");
var Share = require("./Share");
var Header = require("./Header");
var QuickViewOverlay = require("./QuickViewOverlay");
var VideoGallery = require("./VideoGallery");
var SlideInImages = require("./SlideInImages");
var ListGrid = require("./ListGrid");
var VideoFilePlayer = require("./VideoFilePlayer");

var app = function () {
  new SideMenu();
  new SearchOverlay();
  new Contact();
  new Header();
  new QuickViewOverlay();
  new Share();
  new VideoGallery();
  new SlideInImages();
  new ListGrid();
  new VideoFilePlayer();
};

module.exports = app;
