var $ = require("jquery");

var Share = function() {
  var $iconSocial = $(".icon-social-link[data-social-service]");

  var facebook = function() {
    popupCenter(
      "https://www.facebook.com/sharer/sharer.php?u=" + window.location.href,
      "",
      550,
      450
    );
  };

  var twitter = function() {
    popupCenter(
      "http://twitter.com/intent/tweet?text=" + window.location.href,
      "",
      600,
      400
    );
  };

  var linkedin = function() {
    popupCenter(
      "https://www.linkedin.com/shareArticle?mini=true&url=" +
        window.location.href,
      "",
      550,
      450
    );
  };

  var email = function() {
    window.location.href =
      "mailto:?subject=" + document.title + "&body=" + window.location.href;
  };

  var popupCenter = function(url, title, w, h, opts) {
    var _innerOpts = "";
    if (opts !== null && typeof opts === "object") {
      for (var p in opts) {
        if (opts.hasOwnProperty(p)) {
          _innerOpts += p + "=" + opts[p] + ",";
        }
      }
    }
    var dualScreenLeft =
      window.screenLeft != undefined ? window.screenLeft : screen.left;
    var dualScreenTop =
      window.screenTop != undefined ? window.screenTop : screen.top;
    var width = window.innerWidth
      ? window.innerWidth
      : document.documentElement.clientWidth
      ? document.documentElement.clientWidth
      : screen.width;
    var height = window.innerHeight
      ? window.innerHeight
      : document.documentElement.clientHeight
      ? document.documentElement.clientHeight
      : screen.height;
    var left = width / 2 - w / 2 + dualScreenLeft;
    var top = height / 2 - h / 2 + dualScreenTop;
    var newWindow = window.open(
      url,
      title,
      _innerOpts +
        " width=" +
        w +
        ", height=" +
        h +
        ", top=" +
        top +
        ", left=" +
        left
    );
    if (window.focus) {
      newWindow.focus();
    }
  };

  var socialShare = function(event) {
    event.preventDefault();
    switch ($(this).data("socialService")) {
      case "facebook":
        facebook();
        break;
      case "twitter":
        twitter();
        break;
      case "linkedin":
        linkedin();
        break;
      case "email":
        email();
        break;
      default:
        break;
    }
  };

  if ($iconSocial.length) $iconSocial.on("click", socialShare);
};

module.exports = Share;
