var $ = require("jquery");

var SideMenu = function() {
  window.firecrackerFilms.menuOpen = false;
  var inProgress = false;

  $(".menu-item").each(function(index, item) {
    var t = index * 60;
    $(item).css("transition-delay", t + "ms");
  });

  $(".menu-btn").on("click", handleMenuClick);

  function handleMenuClick() {
    if (inProgress == false) {
      inProgress = true;
      window.firecrackerFilms.menuOpen = !window.firecrackerFilms.menuOpen;

      if (window.firecrackerFilms.menuOpen) {
        setTimeout(function() {
          $(".menu-item").addClass("active");
        }, 300);
        setTimeout(function() {
          $(".search-btn").addClass("active");
        }, 200);
        setTimeout(function() {
          $(".menu-social-links").addClass("active");
        }, 600);
        $(document).on("touchstart", function(e) {
          e.preventDefault();
          var $el = $(e.target);

          if ($el.hasClass("icon-close")) {
            handleMenuClick();
          }

          if ($el.hasClass("icon-search") || $el.hasClass("search-label")) {
            handleMenuClick();
            setTimeout(function() {
              $(window).trigger("showSearchOverlay");
            }, 200);
          }

          if (e.target.hasAttribute("href")) {
            window.location = $el.attr("href");
          }
        });
        $("body").addClass("menu-open no-overflow");
      } else {
        $(document).off("touchstart");
        $(".menu-item").removeClass("active");
        $(".search-btn").removeClass("active");
        $(".menu-social-links").removeClass("active");
        $("body").removeClass("menu-open no-overflow");
      }

      // Only add the transition when we're clicking, otherwise the menu transitions
      // its translation during window resizing
      $(".menu-content-wrapper").css(
        "transition",
        "transform 0.4s ease-in-out"
      );
      $(".header .logo a").css("transition", "transform 0.4s ease-in-out");
      $(".header .menu-btn .element").css(
        "transition",
        "transform 0.4s ease-in-out"
      );
      $(".site-content-wrapper").css(
        "transition",
        "transform 0.4s ease-in-out"
      );
      $(".header .breadcrumb-btn .element").css(
        "transition",
        "transform 0.4s ease-in-out"
      );

      setTimeout(function() {
        $(".menu-content-wrapper").css("transition", "none");
        $(".header .logo a").css("transition", "none");
        $(".header .menu-btn .element").css("transition", "none");
        $(".site-content-wrapper").css("transition", "none");
        $(".header .breadcrumb-btn .element").css("transition", "none");
        inProgress = false;
      }, 400);
    }
  }
};

module.exports = SideMenu;
