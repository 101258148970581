var $ = require("jquery");

var Contact = function() {
  $.getScript(
    "https://maps.googleapis.com/maps/api/js?key=AIzaSyDCjPZ1H01VYnbTeDN99REbllptaEHHm1M"
  ).done(handleMapsAPILoaded);

  function handleMapsAPILoaded() {
    require("rich-marker");
    var isDraggable = !("ontouchstart" in document.documentElement);
    var maps = [];
    var latlngs = [];

    $(".contact-map").each(function() {
      var $this = $(this);
      var latlng = new window.google.maps.LatLng(
        $this.data("map-lat"),
        $this.data("map-lng")
      );

      var map = new window.google.maps.Map($this[0], {
        zoom: 15,
        center: latlng,
        disableDefaultUI: false,
        scrollwheel: false,
        draggable: isDraggable
      });

      require("rich-marker");
      // eslint-disable-next-line
      var marker = new RichMarker({
        position: latlng,
        map: map,
        content: "<div class='google-maps-marker'></div>",
        flat: true
      });

      maps.push(map);
      latlngs.push(latlng);
    });

    $(window).on("resize", function() {
      $.each(maps, function(mapIndex, mapItem) {
        window.google.maps.event.trigger(mapItem, "resize");
        mapItem.setCenter(latlngs[mapIndex]);
      });
    });
  }
};
module.exports = Contact;
