var $ = require("jquery");
var Platform = require("./vendor/Platform.js");

var SearchOverlay = function() {
  var $searchOverlay = $("#searchOverlay");
  var $searchOverlayInside = $searchOverlay.find(".search-overlay-inside");
  var $searchBtn = $(".search-btn");
  var $closeBtn = $searchOverlay.find(".close-btn");
  var $searchForm = $searchOverlay.find(".search-form");
  var $input = $searchForm.find(".search-input");
  var $list = $searchOverlay.find("#resultsList");
  var $list2 = $searchOverlay.find("#resultsList2");
  var $form = $searchForm.find("form");

  $searchBtn.on("click", showSearchOverlay);
  $closeBtn.on("click", closeSearchOverlay);
  $(window).on("showSearchOverlay", showSearchOverlay);

  initAutocomplete();

  function showSearchOverlay() {
    $searchOverlay.show();
    $("body").addClass("no-overflow");
    $form.find("input").trigger("focus");
    if (Platform.isTouchDevice()) {
      $("body").scrollTop(0);
    }
    $searchOverlayInside.addClass("active");
  }

  function closeSearchOverlay() {
    $searchOverlayInside.removeClass("active");
    setTimeout(function() {
      $searchOverlay.hide();
      if (!window.firecrackerFilms.menuOpen) {
        $("body").removeClass("no-overflow");
      }
    }, 500);
  }

  function initAutocomplete() {
    var endpoint = $searchForm.data("autocompleteEndpoint");
    $input.on("keydown", function(e) {
      if (e.keyCode == 13) {
        e.preventDefault();
        return;
      }
    });
    $input.on("keypress keyup", function(e) {
      var value = e.target.value;
      if (value.length < 2) {
        return;
      }
      $.ajax({
        type: "GET",
        contentType: "application/json; charset=utf-8",
        url: endpoint + e.target.value,
        dataType: "json",
        success: function(data) {
          $list.empty();
          $list2.empty();
          if (data.length) {
            $form.off("submit");
            $input.on("keydown", function(e) {
              if (e.keyCode == 13) {
                window.location = data[0].fields.url_path;
              }
            });
          } else {
            $form.off("submit");
            $form.on("submit", function(e) {
              e.preventDefault();
            });
            $list.append("<li><span>No results found</span></li>");
          }
          $.each(data, function(i, item) {
            if (data.length <= 8 || Platform.isMobile()) {
              $list.append(
                "<li><a href='" +
                  item.fields.url_path +
                  "'>" +
                  item.fields.title +
                  "</a></li>"
              );
            } else {
              if (i % 2) {
                $list2.append(
                  "<li><a href='" +
                    item.fields.url_path +
                    "'>" +
                    item.fields.title +
                    "</a></li>"
                );
              } else {
                $list.append(
                  "<li><a href='" +
                    item.fields.url_path +
                    "'>" +
                    item.fields.title +
                    "</a></li>"
                );
              }
            }
          });
        }
      });
    });
  }
};

module.exports = SearchOverlay;
