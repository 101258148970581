var $ = require("jquery");
var pace = require("./vendor/pace.min.js");
var App = require("./App");

import * as Sentry from "@sentry/browser";

import { BrowserTracing } from "@sentry/tracing";

if (process.env.NODE_ENV !== "development") {
  try {
    Sentry.init({
      dsn: window.bridge.SENTRY_DSN,
      release: window.bridge.VERSION,
      environment: window.bridge.ENV,
      integrations: [new BrowserTracing()],
      tracesSampleRate: 0.1,
      allowUrls: "firecracker",
    });
  } catch (error) {
    console.log("Error setting up Sentry");
  }
}

(function () {
  var iOS = /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;

  if (iOS) {
    $("body").addClass("ios");
  }

  window.firecrackerFilms = {};
  window.firecrackerFilms.app = new App();

  console.log("Firecracker v003");

  pace.start({
    ajax: false,
    restartOnRequestAfter: false,
  });
})();
