var $ = require("jquery");
var Platform = require("./vendor/Platform.js");

var VideoGallery = function() {
  var $gallery = $(".video-gallery");
  var $player = $gallery.find(".player");
  var $galleryHeader = $gallery.find(".gallery-header");
  var $galleryThumbs = $gallery.find(".gallery-thumbnail");
  var $slider = $gallery.find(".gallery-thumbnails-slider");
  var $arrows = $gallery.find(".arrows");
  var $arrowLeft = $gallery.find(".gallery-arrow-left");
  var $arrowRight = $gallery.find(".gallery-arrow-right");
  var sliderWidth;
  var thumbIndex = 0;
  var thumbIndexMax;
  var inProgress = false;

  if ($galleryThumbs.length == 1) {
    $galleryHeader.hide();
    $gallery.find(".gallery-thumbnails").hide();
  }

  setup();

  $(window).on("resize", function() {
    setup();
  });

  function setup() {
    if ($(window).width() < 768) {
      sliderWidth = 2;
      if ($galleryThumbs.length > 2) {
        $arrows.show();
      } else {
        $arrows.hide();
      }
    } else {
      sliderWidth = 4;
      if ($galleryThumbs.length > 4) {
        $arrows.show();
      } else {
        $arrows.hide();
      }
    }
    thumbIndexMax = Math.ceil($galleryThumbs.length / sliderWidth);
    var offset = Math.floor(thumbIndex / sliderWidth);
    $slider.attr("data-offset", offset);
    thumbIndex = offset * sliderWidth;
  }

  $arrowLeft.on("click", function() {
    if (!inProgress) {
      var currOffset = parseInt($slider.attr("data-offset"));
      if (currOffset > 0) {
        currOffset -= 1;
        $slider.css("transition", "transform 500ms ease-in-out");
        $slider.attr("data-offset", currOffset);
        inProgress = true;
        setTimeout(function() {
          inProgress = false;
          $slider.css("transition", "none");
        }, 500);
      }
      thumbIndex = currOffset * sliderWidth;
    }
  });

  $arrowRight.on("click", function() {
    if (!inProgress) {
      var currOffset = parseInt($slider.attr("data-offset"));
      if (currOffset + 1 < thumbIndexMax) {
        currOffset += 1;
        $slider.css("transition", "transform 500ms ease-in-out");
        $slider.attr("data-offset", currOffset);
        inProgress = true;
        setTimeout(function() {
          inProgress = false;
          $slider.css("transition", "none");
        }, 500);
      }
      thumbIndex = currOffset * sliderWidth;
    }
  });

  $player.on("click", function() {
    var videoId = $player.find("img").data("video-id");
    var videoType = $player.find("img").data("video-type");
    loadVimeo(videoId, videoType);
  });

  // Using $galleryThumbs here was causing a double rollover

  $gallery.find(".gallery-thumbnail .inner").on("mouseover", function() {
    var videoName = $(this)
      .find("img")
      .data("vimeo-name");
    $(".gallery-header-film-name").html(videoName);
  });

  $gallery.find(".gallery-thumbnail .inner").on("mouseout", function() {
    var activeVideoName = $gallery
      .find(".gallery-thumbnail.active")
      .find("img")
      .data("vimeo-name");
    $(".gallery-header-film-name").html(activeVideoName);
  });

  $galleryThumbs.on("click", function(e) {
    $galleryThumbs.removeClass("active");

    var $el = $(e.currentTarget);
    $el.addClass("active");

    var videoId = $el.find("img").data("video-id");
    var videoType = $el.find("img").data("video-type");

    var offset = Platform.isMobile() ? 130 : 190;
    $("html, body").animate(
      {
        scrollTop: offset
      },
      400
    );

    setTimeout(function() {
      loadVimeo(videoId, videoType);
    }, 600);
  });

  function loadVimeo(videoId, videoType) {
    var $iframe;
    if (videoType == "vimeo") {
      $iframe = $(
        "<iframe src='//player.vimeo.com/video/" +
          videoId +
          "?&autoplay=true' frameborder='0' allowfullscreen></iframe>"
      );
    } else {
      $iframe = $(
        "<iframe src='//www.youtube.com/embed/" +
          videoId +
          "?autoplay=true' frameborder='0' webkitallowfullscreen mozallowfullscreen allowfullscreen></iframe>"
      );
    }
    $player.empty();
    $player.append($iframe);
  }
};

module.exports = VideoGallery;
