var UAParser = require('ua-parser-js');

var Platform = function() {
  var OS, browser, device, isException, result, table;

  result = new UAParser().getResult();
  device = result.device;
  browser = result.browser;
  OS = result.os;

  this.isRetina = function() {
    return !!((window.devicePixelRatio || (window.screen.deviceXDPI / window.screen.logicalXDPI) || 1) > 1);
  };

  this.isMobile = function() {
    return window.innerWidth < 767;
  };

  this.isMobileDevice = function() {
    return device.type === 'mobile';
  };

  this.isTablet = function() {
    return device.type === 'tablet';
  };

  this.isIE = function() {
    return browser.name === 'IE';
  };

  this.isEdge = function() {
    return browser.name === 'Edge';
  };

  this.isIos = function() {
    return OS.name === 'iOS';
  };

  this.isAndroid = function() {
    return OS.name === 'Android';
  };

  this.isDesktop = function() {
    return OS.name === 'Windows' || OS.name === 'Mac OS' || OS.name === 'Linux';
  };

  this.isTouchDevice = function() {
    var e;
    try {
      document.createEvent('TouchEvent');
      return true;
    } catch (_error) {
      e = _error;
      return false;
    }
  };
};

module.exports = new Platform;