var $ = require("jquery");
require("jquery-match-height");
var Platform = require("./vendor/Platform.js");

var ListGrid = function() {
  var $listColumns = $(".col-half, .col-one-third");

  if (Platform.isTablet()) {
    var showOverlay = function(e) {
      var $el = $(e.currentTarget);
      var hideOverlay = function(e) {
        if (
          !$(e.target)
            .parents()
            .andSelf()
            .is(".project-tile-inside")
        ) {
          $el.removeClass("tapped");
          $el.on("click", showOverlay);
          $("body").off("touchstart", hideOverlay);
        }
      };
      $listColumns.find(".project-tile-inside").removeClass("tapped");
      $el.addClass("tapped");
      $el.off("click", showOverlay);
      $("body").on("touchstart", hideOverlay);
      e.preventDefault();
      e.stopPropagation();
    };
    $listColumns.find(".project-tile-inside").on("click", showOverlay);
  }
};

module.exports = ListGrid;
